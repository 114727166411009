<template>
	<div class="relative w-full h-48">
		<div class="absolute -bottom-16 w-full">
			<div
				data-aos="fade-up"
				class="container  mx-auto bg-primary px-12 py-16 rounded-lg flex items-center justify-center md:justify-between flex-wrap md:flex-nowrap text-right text-white"
			>
				<div class="leading-relaxed tracking-wide w-full md:w-auto text-center md:text-right">
					<h1 class="mb-2 font-bold text-2xl">للتواصل مع الشركة</h1>
					<h6 class="text-lg font-medium">لا تتردد في التواصل معنا لأي استفسار او خدمة</h6>
				</div>
				<router-link :to="{ name: 'Contact' }" class="bg-white rounded-md text-primary mt-5 md:mt-0 px-5 py-2"
					>صفحة التواصل</router-link
				>
			</div>
		</div>
	</div>
</template>
